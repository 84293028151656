// @file All network requests used by payment plan store
import { fetchJson } from '@@/surface/api_fetch'
import type {
  AddLicenseResponse,
  AdvertisedQuotasApiResult,
  BillingPauseResult,
  EstimateCancellationRefundResult,
  EstimateSwitchingCostResult,
  InvoiceCheckResponse,
  LibraryId,
  LibraryPlan as LibraryPlanType,
  Plan as PlanType,
  RemoveLicenseResponse,
  ScheduledChangesResult,
  SwitchCurrencyResult,
  SwitchPlanResult,
  UndoScheduledChangeResult,
} from '@@/types'
import type { Billing as BillingType, JsonAPIResponse } from '@padlet/arvo'
import { fetchBilling } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

// Ensure this version is synced with `mozart/tests/playwright/helpers/dashboard_test.ts`
const API_VERSION = 5

class Billing {
  public static async fetch(): Promise<JsonAPIResponse<BillingType>> {
    return await fetchBilling()
  }

  public static async pause(fetchOptions = {}): Promise<BillingPauseResult> {
    // note: not a JSON:API formatted api call
    return await fetchJson('/premium/pause', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async resume(fetchOptions = {}): Promise<BillingPauseResult> {
    // note: not a JSON:API formatted api call
    return await fetchJson('/premium/resume', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  /**
   * Fetch estimated refund.
   */
  public static async estimateCancellationRefund(
    fetchOptions = {},
  ): Promise<JsonAPIResponse<EstimateCancellationRefundResult>> {
    return await fetchJson(`/premium/cancel/refund_estimate`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async cancelPause(fetchOptions = {}): Promise<BillingPauseResult> {
    // note: not a JSON:API formatted api call
    return await fetchJson('/premium/cancel_pausing', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async undoScheduledCancellation(fetchOptions = {}): Promise<UndoScheduledChangeResult> {
    return await fetchJson('/premium/undo_scheduled_cancellation', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async undoScheduledSwitch(fetchOptions = {}): Promise<UndoScheduledChangeResult> {
    return await fetchJson('/premium/undo_scheduled_plan_switch', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async estimateSwitchingCost(fetchOptions = {}): Promise<EstimateSwitchingCostResult> {
    return await fetchJson('/premium/switch_plan/estimate_cost', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async switchPlan(fetchOptions = {}): Promise<SwitchPlanResult> {
    return await fetchJson('/premium/switch_plan', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async switchCurrency(fetchOptions = {}): Promise<SwitchCurrencyResult> {
    return await fetchJson('/premium/switch_currency', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async cancelMembership(fetchOptions = {}): Promise<SwitchPlanResult> {
    return await fetchJson('/premium/cancel', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async fetchScheduledChanges(): Promise<ScheduledChangesResult> {
    return await fetchJson('/api/3/billing/scheduled_changes', {
      method: HTTPMethod.get,
    })
  }

  public static async fetchBulkProInvoice(fetchOptions = {}): Promise<JsonAPIResponse<InvoiceCheckResponse>> {
    return await fetchJson('/api/1/premium/activate-bulk-pro/check', {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async addUsersToBulkProLicense(fetchOptions = {}): Promise<JsonAPIResponse<AddLicenseResponse>> {
    return await fetchJson('/api/1/premium/activate-bulk-pro/add-license', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async removeUsersFromBulkProLicense(
    fetchOptions = {},
  ): Promise<JsonAPIResponse<RemoveLicenseResponse>> {
    return await fetchJson('/api/1/premium/activate-bulk-pro/remove-license', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }
}

class Checkout {
  public static async fetchCheckoutPageUrl(
    body: { nextUrl: string; priceId: string; libraryId?: string },
    fetchOptions = {},
  ): Promise<{ checkoutPageUrl: string }> {
    return await fetchJson('/api/1/premium/checkout/init', {
      method: HTTPMethod.post,
      jsonData: body,
      ...fetchOptions,
    })
  }
}

class Plan {
  /**
   * Fetch list of available plans for current user (respective to the user's country code/currency)
   */
  public static async fetch(fetchOptions = {}): Promise<JsonAPIResponse<PlanType>> {
    return await fetchJson(`/api/${API_VERSION}/plans`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchAdvertisedQuotas(fetchOptions = {}): Promise<AdvertisedQuotasApiResult> {
    return await fetchJson('/api/3/membership_tier_quotas', {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

class Ab {
  public static async convertAbExperimentGoal(
    experiment_name: string,
    conversion_goal: string,
  ): Promise<JsonAPIResponse<any>> {
    return await fetchJson('/api/1/ab-test/convert', {
      method: HTTPMethod.post,
      jsonData: {
        experiment_name,
        conversion_goal,
      },
    })
  }

  public static async convertPersonalPricingPlansExperiment(): Promise<JsonAPIResponse<any>> {
    return await fetchJson('/api/1/ab-test/pricing-plans/convert', {
      method: HTTPMethod.post,
    })
  }
}

class LibraryPlan {
  /**
   * Fetch list of available library plans for current user (respective to the user's country code/currency)
   */
  public static async fetch(fetchOptions = {}): Promise<JsonAPIResponse<LibraryPlanType>> {
    return await fetchJson(`/api/${API_VERSION}/team_plans`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  /**
   * Fetch list of available library plans for the current user and a specific library,
   * based on the user's signup country and the library's type.
   *
   * Returns team plans for team libraries, and classroom plans for classroom libraries.
   *
   * Does not return school libraries because school plans are not self-serve.
   * School library users have to contact Customer Success to subscribe instead.
   */
  public static async fetchByLibraryId(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryPlanType>> {
    return await fetchJson(`/api/${API_VERSION}/library_plans/${libraryId}`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

export { Ab, Billing, Checkout, Plan, LibraryPlan }
